import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 596.000000 419.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,419.000000) scale(0.100000,-0.100000)">



<path d="M983 2682 c-28 -18 -33 -70 -33 -365 1 -388 8 -476 49 -563 37 -80
123 -166 206 -208 119 -60 274 -59 400 3 123 61 177 140 264 393 61 178 68
191 123 245 66 64 126 87 228 88 73 0 93 -4 142 -28 197 -97 251 -357 108
-521 -101 -115 -286 -143 -419 -63 -33 19 -62 33 -65 29 -9 -8 -7 -105 2 -118
15 -23 94 -55 166 -66 200 -31 388 68 474 249 l37 78 3 409 c3 374 2 410 -14
427 -20 23 -53 24 -80 5 -18 -13 -19 -30 -22 -225 l-3 -212 -51 41 c-205 163
-437 149 -609 -37 -60 -64 -70 -85 -120 -240 -87 -270 -152 -352 -303 -383
-110 -23 -244 22 -311 105 -76 93 -80 107 -83 347 l-4 217 89 3 88 3 0 50 0
50 -85 5 -85 5 -5 128 c-4 112 -7 130 -24 143 -22 15 -45 18 -63 6z m45 -175
l3 -137 84 0 c78 0 85 -2 85 -20 0 -18 -7 -20 -86 -20 l-85 0 3 -237 3 -238
29 -63 c37 -80 122 -161 203 -193 79 -31 196 -31 274 -1 129 51 195 152 279
429 45 150 106 225 233 288 88 43 154 52 236 31 85 -22 143 -58 231 -143 41
-40 75 -73 75 -73 1 0 -1 53 -3 118 -2 64 -1 181 3 260 6 114 10 142 22 142
12 0 14 -55 11 -402 l-3 -403 -31 -65 c-17 -36 -47 -83 -67 -105 -77 -88 -216
-142 -337 -132 -76 7 -159 33 -167 54 -10 25 5 26 56 5 215 -93 468 45 509
278 29 164 -62 336 -216 408 -49 23 -70 27 -152 27 -81 0 -103 -4 -151 -27
-64 -29 -131 -86 -164 -138 -12 -19 -43 -100 -70 -179 -59 -177 -113 -289
-162 -332 -20 -17 -66 -46 -102 -62 -209 -98 -468 10 -550 228 -20 53 -21 76
-21 451 0 312 3 395 13 392 9 -3 13 -43 15 -141z"/>
<path d="M3140 2417 c-57 -20 -70 -58 -70 -212 0 -196 18 -235 108 -235 95 0
112 34 112 228 0 163 -9 198 -56 218 -39 17 -48 17 -94 1z m61 -57 c17 -10 19
-23 19 -158 0 -153 -5 -172 -42 -172 -30 0 -38 35 -38 174 0 109 3 138 16 150
18 19 20 19 45 6z"/>
<path d="M2844 2405 c-10 -26 4 -44 38 -47 l33 -3 3 -193 2 -193 33 3 32 3 3
193 2 192 35 0 c32 0 35 2 35 30 l0 30 -105 0 c-85 0 -107 -3 -111 -15z"/>
<path d="M3322 2198 l3 -223 32 -3 32 -3 3 83 3 83 35 6 c80 13 100 41 100
144 0 112 -24 135 -141 135 l-69 0 2 -222z m133 88 c0 -73 -14 -96 -49 -83
-13 5 -16 21 -16 75 0 38 3 72 7 76 4 4 19 6 33 4 24 -3 25 -6 25 -72z"/>
<path d="M3560 2418 c-1 -2 -2 -102 -2 -223 l-1 -220 31 -3 32 -3 0 225 0 226
-30 0 c-16 0 -30 -1 -30 -2z"/>
<path d="M3662 2198 l3 -223 28 -3 27 -3 0 152 c0 96 4 149 9 144 6 -6 27 -73
48 -150 l39 -140 34 0 35 0 3 223 2 222 -30 0 c-34 0 -32 8 -30 -142 1 -50 -3
-88 -8 -88 -9 0 -28 62 -60 190 -8 33 -11 35 -55 38 l-47 3 2 -223z"/>
<path d="M3930 2195 l0 -225 76 0 c70 0 78 2 105 29 l29 29 0 172 c0 168 0
171 -25 195 -22 22 -32 25 -105 25 l-80 0 0 -225z m134 153 c3 -7 7 -74 10
-150 7 -149 1 -168 -50 -168 l-24 0 0 165 0 165 30 0 c17 0 32 -6 34 -12z"/>
<path d="M4226 2393 c-7 -36 -54 -349 -58 -388 -3 -26 1 -30 23 -33 24 -3 28
2 36 35 8 37 10 38 55 41 l47 3 3 -38 c3 -35 6 -38 37 -41 l34 -3 -6 48 c-8
58 -32 219 -50 323 l-13 75 -51 3 c-49 3 -51 2 -57 -25z m73 -176 c15 -107 15
-107 -17 -107 -39 0 -40 3 -31 58 4 26 10 69 14 96 9 64 20 48 34 -47z"/>
<path d="M4382 2393 c2 -23 9 -29 37 -33 l34 -5 -2 -192 -2 -193 35 0 36 0 0
128 c0 70 3 157 7 195 l6 67 33 0 c31 0 34 3 34 30 l0 30 -111 0 -110 0 3 -27z"/>
<path d="M4645 2408 c-7 -21 -65 -394 -65 -417 0 -16 6 -21 29 -21 25 0 29 4
35 40 6 40 7 40 51 40 44 0 45 0 51 -40 6 -37 9 -40 40 -40 19 0 34 2 34 5 0
7 -49 313 -61 383 l-11 62 -49 0 c-31 0 -51 -5 -54 -12z m71 -195 c7 -48 10
-91 8 -95 -3 -4 -19 -8 -36 -8 -30 0 -30 1 -24 38 4 20 9 60 12 87 12 105 22
99 40 -22z"/>
<path d="M2865 1880 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2910 1875 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3270 1870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4693 1840 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3146 1861 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M4033 1820 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M4880 1849 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3279 1838 c-6 -24 -5 -58 2 -58 5 0 9 14 9 30 0 17 -2 30 -5 30 -3
0 -5 -1 -6 -2z"/>
<path d="M4290 1824 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4392 1810 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3822 1810 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3860 1799 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M2910 1794 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3100 1785 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
